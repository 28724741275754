import { Reference, StoreObject } from '@apollo/client';
import { useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { useClient } from '@splitsoftware/splitio-react';
import { useAtom, WritableAtom } from 'jotai';
import md5 from 'md5';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getByOktaRole, isAdminCoachOrOs } from '../components/utils';
import { SseEvents } from '../constants/strings/events';
import { GQL_ERROR_POLICY_ALL, PAGE_SIZE } from '../constants/strings/gql';
import { NAV_SPLIT_TREATMENTS, ON } from '../constants/strings/split';
import { commonCoachCompletedStates, commonCoachExcludedStates, QueueCategoryType } from '../custom-types';
import { videoVisitFollowupStates, VideoVisitsState } from '../modules/acute-physical-therapy/custom-types';
import { commonOsCompletedStates } from '../modules/dashboard/types/os';
import { commonPtCompletedStates, commonPtExcludedStates } from '../modules/dashboard/types/pt';
import { getEndOfTomorrowInBusinessDays } from '../modules/dashboard/utils/get-formatted-date';
import { FetchMoreWorkflowsAtom } from '../state/queue-atoms';
import { useGetWorkflowsByFilterForQueueV1LazyQuery, WorkflowListFiltersInputDto, WorkflowPayload } from '../types';
import { getWorkflowSseData } from '../utils/coach-sse';
import { defaultErrorHandler } from '../utils/errors';
import { useCurrentStoredAdminHook } from './use-current-stored-admin';
import { useMethodToSetTempNextIdentifier } from './use-method-to-set-temp-next-identifier';
import { useSse } from './use-sse';
export const useGetQueueWorkflows = ({
  workflowCategoryAtom,
  type
}: {
  workflowCategoryAtom: WritableAtom<FetchMoreWorkflowsAtom, unknown[], void>;
  type?: QueueCategoryType;
}): {
  workflows: WorkflowPayload[] | undefined;
  loading: boolean;
  showMoreClickHandler: () => Promise<void>;
  fetchQueueWorkflows: () => void;
} => {
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  const [queueAtom, setQueueAtom] = useAtom(workflowCategoryAtom);
  const {
    hingeHealthAuthState
  } = useHingeHealthSecurityContext();
  const roles = ((hingeHealthAuthState?.accessToken?.claims.roles ?? []) as string[]);
  const isCoachOrOs = useMemo(() => isAdminCoachOrOs(roles),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [hingeHealthAuthState]);
  let commonExcludedStates;
  if (isCoachOrOs) {
    commonExcludedStates = commonCoachExcludedStates;
  } else if (type === QueueCategoryType.Encounter) {
    commonExcludedStates = [...commonPtExcludedStates, ...videoVisitFollowupStates];
  } else {
    commonExcludedStates = [VideoVisitsState.CompleteVideoVisit, ...commonPtExcludedStates];
  }
  const commonCompletedStates = getByOktaRole(commonCoachCompletedStates, commonOsCompletedStates, commonPtCompletedStates, roles);
  const dueTaskEndDate = isCoachOrOs ? getEndOfTomorrowInBusinessDays() : undefined;
  const useCoverage = isCoachOrOs;
  // Here we are maintaining a separate state for workflowFiltersInput
  // because of the bug in apollo-client which will trigger the network
  // call 2 times when refetch is called, once with actual variables called
  // and the other with the variables intially set at the time of initialisation.
  // More info on the bug - https://github.com/apollographql/apollo-client/issues/2285

  // Since we now use state as variables, the arguments would be the same both the times
  // and by default apollo uses cache-first policy (https://www.apollographql.com/docs/react/data/queries/#cache-first)
  // the second call due to bug will not be triggered, thus solving our issue
  const [workflowFiltersInitialInput, setWorkflowFiltersInitialInput] = useState<WorkflowListFiltersInputDto>({
    excludeStates: commonExcludedStates
  });
  const [getQueueWorkflows, {
    data,
    error,
    loading,
    fetchMore,
    refetch,
    client
  }] = useGetWorkflowsByFilterForQueueV1LazyQuery({
    variables: {
      workflowFiltersInput: workflowFiltersInitialInput // Here we are using the state due to the bug descibed above
    }
  });
  const [workflows, setWorkflows] = useState<WorkflowPayload[]>([]);
  const [workflowsLoading, setWorkflowsLoading] = useState(loading);
  const {
    setTemporaryNextIdentifier
  } = useMethodToSetTempNextIdentifier();
  const splitClient = useClient();
  const listIdMd5 = md5(JSON.stringify({
    workflowTypes: queueAtom.queueCategory,
    excludeStates: commonExcludedStates
  }));
  const normalizedListId = client.cache.identify({
    id: listIdMd5,
    __typename: 'WorkflowListPayload'
  });
  const workflowsQueueSplitOn = splitClient?.getTreatment(NAV_SPLIT_TREATMENTS.WORKFLOWS_QUEUE, {
    adminUuid: currentStoredAdmin?.uuid || '*'
  }) === ON;
  const sseData = useSse(`${process.env.REACT_APP_BFF_URL}/user-workflows/subscribe`, [SseEvents.WorkflowTransitioned, SseEvents.WorkflowCreated, SseEvents.TaskUpdated], {
    replayLastEvent: true
  });
  const fetchQueueWorkflows = useCallback(() => {
    const fetchWorkflowsData = async (currentAdminUuid: string, fetchWorkflowsAtom: FetchMoreWorkflowsAtom): Promise<void> => {
      setWorkflowsLoading(true);
      const workflowFiltersInput: WorkflowListFiltersInputDto = {
        adminUuids: [currentAdminUuid],
        workflowTypes: fetchWorkflowsAtom.queueCategory,
        excludeStates: commonExcludedStates,
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        dueTaskEndDate,
        mergePaginatedResult: true,
        useCoverage,
        ...(!isCoachOrOs && {
          completedStates: commonPtCompletedStates
        })
      };
      await getQueueWorkflows({
        variables: {
          workflowFiltersInput
        },
        errorPolicy: GQL_ERROR_POLICY_ALL,
        onCompleted: () => {
          setWorkflowsLoading(false);
        },
        onError: () => {
          setWorkflowsLoading(false);
        }
      });
    };
    if (currentStoredAdmin?.uuid && workflowsQueueSplitOn) {
      fetchWorkflowsData(currentStoredAdmin?.uuid, queueAtom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStoredAdmin?.uuid, getQueueWorkflows, workflowsQueueSplitOn]);
  useEffect(() => {
    if (error) {
      defaultErrorHandler(error.message);
    }
    const workflowsFromList = data?.getWorkflowsByFilter?.workflows;
    if (workflowsFromList && workflowsFromList.length > 0 && !loading && !workflowsLoading) {
      setWorkflows((workflowsFromList as WorkflowPayload[]));
    }
  }, [data, error, loading, workflowsLoading]);
  useEffect(() => {
    if (sseData && workflowsQueueSplitOn && currentStoredAdmin?.uuid) {
      const workflow = getWorkflowSseData(sseData);
      const {
        serviceName,
        type: type_0,
        stateName,
        workflowId
      } = workflow;
      const alreadyFetchedPageSize = queueAtom.fetchPageNumber * PAGE_SIZE;

      // Cached query refetches the count of all workflows already fetched
      // This method is only used when a new workflow is added
      // and only up to the first 50 workflows
      const refetchAlreadyFetchedWorkflows = async (): Promise<void> => {
        setTemporaryNextIdentifier();
        const pageSizeToFetch = queueAtom.categoryTotal - alreadyFetchedPageSize >= 0 ? alreadyFetchedPageSize : queueAtom.categoryTotal;
        const workflowFiltersInput_0: WorkflowListFiltersInputDto = {
          adminUuids: [currentStoredAdmin.uuid],
          workflowTypes: queueAtom.queueCategory,
          excludeStates: commonExcludedStates,
          pageNumber: 1,
          pageSize: pageSizeToFetch,
          dueTaskEndDate,
          mergePaginatedResult: true,
          useCoverage,
          ...(!isCoachOrOs && {
            completedStates: commonPtCompletedStates
          })
        };
        setWorkflowFiltersInitialInput(workflowFiltersInput_0);
        await refetch({
          workflowFiltersInput: workflowFiltersInput_0
        });
      };

      // Modify the cache to be an empty list
      // Then sequentially fetchMore each page till the user has fetched
      // Method used for workflow refetch for over 50 workflows
      const refetchPageOneThenRest = async (): Promise<void> => {
        setWorkflowsLoading(true);
        client.cache.modify({
          id: normalizedListId,
          fields: {
            workflows() {
              return [];
            }
          }
        });
        const fetchPageNumberArray = [...Array(queueAtom.fetchPageNumber).keys()];
        fetchPageNumberArray.shift();
        fetchPageNumberArray.push(queueAtom.fetchPageNumber);
        for await (const number of fetchPageNumberArray) {
          const workflowFiltersInput: WorkflowListFiltersInputDto = {
            adminUuids: [currentStoredAdmin.uuid],
            workflowTypes: queueAtom.queueCategory,
            excludeStates: commonExcludedStates,
            pageNumber: number,
            pageSize: PAGE_SIZE,
            dueTaskEndDate,
            mergePaginatedResult: true,
            useCoverage,
            ...(!isCoachOrOs && {
              completedStates: commonPtCompletedStates
            })
          };
          await fetchMore({
            variables: {
              workflowFiltersInput
            }
          });
        }
        setWorkflowsLoading(false);
      };
      const fetchOneMoreAfterRemoval = async (): Promise<void> => {
        const pageToFetch = queueAtom.categoryTotal - alreadyFetchedPageSize < 0 ? queueAtom.categoryTotal : alreadyFetchedPageSize;
        const workflowFiltersInput_1: WorkflowListFiltersInputDto = {
          adminUuids: [currentStoredAdmin.uuid],
          workflowTypes: queueAtom.queueCategory,
          excludeStates: commonExcludedStates,
          pageNumber: pageToFetch,
          pageSize: 1,
          dueTaskEndDate,
          mergePaginatedResult: true,
          useCoverage,
          ...(!isCoachOrOs && {
            completedStates: commonPtCompletedStates
          })
        };
        await fetchMore({
          variables: {
            workflowFiltersInput: workflowFiltersInput_1
          }
        });
        setWorkflowsLoading(false);
      };

      // Remove the completed/ due later workflow from cache
      // and set temporary identifier to move to next workflow
      const removeFromCacheAndFetchOneMore = async (): Promise<void> => {
        setWorkflowsLoading(true);
        let fetchOneMoreWorkflow = false;
        client.cache.modify({
          id: normalizedListId,
          fields: {
            workflows(existingWorkflowRefs, {
              readField
            }) {
              const indexOfWorkflowInCache = existingWorkflowRefs.findIndex((workflowRef: StoreObject | Reference | undefined) => workflowId === readField('id', workflowRef));
              if (indexOfWorkflowInCache === -1) {
                return;
              } else {
                setTemporaryNextIdentifier();
                fetchOneMoreWorkflow = true;
                return existingWorkflowRefs.filter((workflowRef_0: StoreObject | Reference | undefined) => workflowId !== readField('id', workflowRef_0));
              }
            }
          }
        });
        if (fetchOneMoreWorkflow && queueAtom.categoryTotal > workflows.length) {
          await fetchOneMoreAfterRemoval();
        }
      };
      if (queueAtom.queueCategory.includes(serviceName)) {
        switch (type_0) {
          case SseEvents.WorkflowTransitioned:
            if (commonCompletedStates.includes(stateName)) {
              if (queueAtom.fetchPageNumber < 6) {
                refetchAlreadyFetchedWorkflows();
              } else {
                removeFromCacheAndFetchOneMore();
              }
            }
            break;
          case SseEvents.WorkflowCreated:
          case SseEvents.TaskUpdated:
            //EMO-3509: when workflowDueDate is available switch this
            if (queueAtom.fetchPageNumber < 6) {
              refetchAlreadyFetchedWorkflows();
            } else {
              refetchPageOneThenRest();
            }
            break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStoredAdmin?.uuid, queueAtom.categoryTotal, workflowsQueueSplitOn]);
  const showMoreClickHandler = async (): Promise<void> => {
    const nextPageNumber = Math.ceil(workflows.length / PAGE_SIZE) + 1;
    if (currentStoredAdmin?.uuid && workflowsQueueSplitOn) {
      setWorkflowsLoading(true);
      const workflowFiltersInput_2: WorkflowListFiltersInputDto = {
        adminUuids: [currentStoredAdmin?.uuid],
        pageNumber: nextPageNumber,
        pageSize: PAGE_SIZE,
        workflowTypes: queueAtom.queueCategory,
        excludeStates: commonExcludedStates,
        dueTaskEndDate,
        mergePaginatedResult: true,
        useCoverage,
        ...(!isCoachOrOs && {
          completedStates: commonPtCompletedStates
        })
      };
      await fetchMore({
        variables: {
          workflowFiltersInput: workflowFiltersInput_2
        }
      });
      setQueueAtom((prev: FetchMoreWorkflowsAtom) => ({
        ...prev,
        fetchPageNumber: nextPageNumber
      }));
      setWorkflowsLoading(false);
    }
  };
  return {
    workflows,
    loading: workflowsLoading,
    showMoreClickHandler,
    fetchQueueWorkflows
  };
};