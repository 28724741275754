import { AccessToken } from '@okta/okta-auth-js';
import dayjs from 'dayjs';
import { defaultErrorHandler } from './errors';
interface EventCallback {
  callbacks: Record<string, (event: Event) => void>;
  last?: MessageEvent;
}
interface SseClientParams {
  url: string;
  accessToken: AccessToken;
  getOrRenewAccessToken: () => Promise<string | null>;
}
export class SseClient {
  private eventSource?: EventSource;
  private eventCallbacks: Map<string, EventCallback>;
  public initialized = false;
  constructor() {
    this.eventCallbacks = new Map();
    this.eventSource = undefined;
  }
  private setTokenCookieForDomain(url: string, token: string): void {
    try {
      const urlString = new URL(url);
      const hostname = urlString.hostname;
      if (hostname === 'localhost') {
        document.cookie = `token=${token}; Secure`;
      } else {
        const domain = `.${hostname.split('.').slice(1).join('.')}`;
        document.cookie = `token=${token}; domain=${domain}; Secure`;
      }
    } catch (error) {
      console.warn('Error setting domain in cookie:', error);
      document.cookie = `token=${token}; Secure`;
    }
  }
  private appendTokenToURL(url: string, token: string): string {
    try {
      const urlString = new URL(url);
      urlString.searchParams.set('token', token); // add or update the token query parameter
      return urlString.toString();
    } catch (error) {
      console.warn('Error parsing URL', error);
    }
    return url;
  }
  public async init({
    url,
    accessToken,
    getOrRenewAccessToken
  }: SseClientParams): Promise<void> {
    const token = await this.maybeRenewToken({
      url,
      accessToken,
      getOrRenewAccessToken
    }).catch(error => {
      defaultErrorHandler(`Okta error: ${error.message}`);
    });
    if (!token) {
      console.warn('SSEClient: no token');
      this.initialized = false;
      return;
    }
    if (process.env.DISABLE_SSE_COOKIES === 'true') {
      url = this.appendTokenToURL(url, token);
    } else {
      this.setTokenCookieForDomain(url, token);
    }
    this.eventSource = new EventSource(url, {
      withCredentials: true
    });
    this.eventSource.onerror = this.onErrorCallback({
      url,
      accessToken,
      getOrRenewAccessToken
    });
    this.eventSource.onmessage = this.onMessageCallback.bind(this);
  }
  private onErrorCallback(params: SseClientParams) {
    return async (event: Event): Promise<void> => {
      if (this.eventSource?.readyState === EventSource.CLOSED) {
        console.log('SSEClient: evensource is closed. Reinitializing', event);
        await this.init(params);
      } else {
        console.warn('SSEClient: unhandled error callback', event);
      }
    };
  }
  private onMessageCallback(event: MessageEvent): void {
    const parsedEvent = JSON.parse(event.data);
    const eventType = parsedEvent?.type;
    const eventHandler = this.eventCallbacks.get(eventType);
    const eventHandlerCallbacks = Object.values(eventHandler?.callbacks || {});
    if (eventHandlerCallbacks.length) {
      eventHandlerCallbacks.forEach(callback => {
        callback(parsedEvent);
      });
    } else {
      if (eventType !== 'keepAlive') {
        console.warn('No handler for incoming event', parsedEvent?.type);
      }
    }
    this.eventCallbacks.set(eventType, {
      callbacks: eventHandler?.callbacks || {},
      last: parsedEvent
    });
  }
  private async maybeRenewToken({
    accessToken,
    getOrRenewAccessToken
  }: SseClientParams): Promise<string> {
    const expiresAt = dayjs.unix(accessToken.expiresAt); // unix takes time
    if (expiresAt.diff(dayjs(), 'seconds') > 10) {
      return accessToken?.accessToken;
    }
    const newToken = await getOrRenewAccessToken();
    if (!newToken) {
      throw Error('Cannot renew access token (token is empty)');
    }
    return newToken;
  }
  public replay(eventType: string): void {
    const eventHandler = this.eventCallbacks.get(eventType);
    const callbacks = Object.values(eventHandler?.callbacks || {});
    if (!callbacks.length || !eventHandler?.last) {
      return;
    }
    callbacks.forEach(callback => {
      if (eventHandler.last) {
        callback(eventHandler.last);
      }
    });
  }
  public subscribe(message: string, subscriberId: string, callback: (event: Event) => unknown): void {
    const eventHandlers = this.eventCallbacks.get(message);
    const callbacks = eventHandlers?.callbacks || {};
    this.eventCallbacks.set(message, {
      callbacks: {
        ...callbacks,
        [subscriberId]: callback
      },
      last: eventHandlers?.last
    });
  }
  public unsubscribe(message: string, subscriberId: string): void {
    const eventHandler = this.eventCallbacks.get(message);
    if (!eventHandler) {
      return;
    }
    const {
      [subscriberId]: removedSubscriberId,
      ...otherCallbacks
    } = eventHandler.callbacks;
    this.eventCallbacks.set(message, {
      callbacks: otherCallbacks,
      last: eventHandler.last
    });
  }
  public close(): void {
    this.eventSource?.close();
    this.initialized = false;
  }
}