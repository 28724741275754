import { c as _c } from "react-compiler-runtime";
import { HHButton, HHChip, HHDialogActions, HHDialogContent, HHDialogContentText, HHList, HHListItem, HHSelect, HHSelectChangeEvent, HHStack, HHTextField, HHTypography, TextArea, Typography } from '@hinge-health/react-component-library';
import { DeleteOutlineRounded, DoneRounded, InfoOutlined, UploadFileSharp } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { useRef, useState } from 'react';
import { FRESHDESK_FIELDS, USER_FEEDBACK } from '../../../constants/strings/user-feedback';
import { FeedbackMessage, FeedbackType } from './feedback-dialog';
import { FreshdeskTicketCustomFields, FreshdeskTicketDetails } from './freshdesk-dialog';
export const FreshdeskSubmissionForm = t0 => {
  const $ = _c(113);
  let props;
  if ($[0] !== t0) {
    ({
      ...props
    } = t0);
    $[0] = t0;
    $[1] = props;
  } else {
    props = $[1];
  }
  const fileInputRef = useRef(null);
  const [typeChip, setTypeChip] = useState(USER_FEEDBACK.BUG_STRING);
  const [role, setRole] = useState("");
  const [request, setRequest] = useState("");
  let t1;
  if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };
    $[2] = t1;
  } else {
    t1 = $[2];
  }
  const handleButtonClick = t1;
  let t2;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = type => {
      setTypeChip(type);
    };
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  const handleToggleBugFeatureChip = t2;
  let t3;
  if ($[4] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = {
      padding: "0px 24px !important"
    };
    $[4] = t3;
  } else {
    t3 = $[4];
  }
  let t4;
  if ($[5] === Symbol.for("react.memo_cache_sentinel")) {
    t4 = {
      padding: "3px 9px 0px 2px"
    };
    $[5] = t4;
  } else {
    t4 = $[5];
  }
  let t5;
  if ($[6] === Symbol.for("react.memo_cache_sentinel")) {
    t5 = <HHTypography hhVariant="h4" layoutStyles={t4}>{USER_FEEDBACK.DIALOG_TEXTS.ABOUT_YOU}</HHTypography>;
    $[6] = t5;
  } else {
    t5 = $[6];
  }
  let t6;
  if ($[7] === Symbol.for("react.memo_cache_sentinel")) {
    t6 = {
      padding: "12px 1px 12px 0px"
    };
    $[7] = t6;
  } else {
    t6 = $[7];
  }
  let t7;
  if ($[8] !== props) {
    t7 = e => {
      const role_0 = (e.target.value as string);
      setRole(role_0);
      props.updateCustomFields(prevData => ({
        ...prevData,
        cf_internal_requester_type: role_0
      }));
    };
    $[8] = props;
    $[9] = t7;
  } else {
    t7 = $[9];
  }
  let t8;
  if ($[10] === Symbol.for("react.memo_cache_sentinel")) {
    t8 = FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.CF_INTERNAL_REQUESTER_TYPE.map(option => ({
      value: option,
      label: option,
      displayName: option
    }));
    $[10] = t8;
  } else {
    t8 = $[10];
  }
  let t9;
  if ($[11] !== role || $[12] !== t7) {
    t9 = <HHSelect initialSelectValue={role} label={USER_FEEDBACK.DIALOG_TEXTS.ROLE} onChange={t7} selectOptions={t8} size="small" width="250px" />;
    $[11] = role;
    $[12] = t7;
    $[13] = t9;
  } else {
    t9 = $[13];
  }
  let t10;
  if ($[14] !== props) {
    t10 = e_0 => {
      const request_0 = (e_0.target.value as string);
      setRequest(request_0);
      props.updateCustomFields(prevData_0 => ({
        ...prevData_0,
        cf_request_type_new: request_0
      }));
    };
    $[14] = props;
    $[15] = t10;
  } else {
    t10 = $[15];
  }
  let t11;
  if ($[16] === Symbol.for("react.memo_cache_sentinel")) {
    t11 = FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.CF_REQUEST_TYPE_NEW.map(option_0 => ({
      value: option_0,
      label: option_0,
      displayName: option_0
    }));
    $[16] = t11;
  } else {
    t11 = $[16];
  }
  let t12;
  if ($[17] !== request || $[18] !== t10) {
    t12 = <HHSelect initialSelectValue={request} label={USER_FEEDBACK.DIALOG_TEXTS.SUPPORT} onChange={t10} selectOptions={t11} size="small" width="300px" />;
    $[17] = request;
    $[18] = t10;
    $[19] = t12;
  } else {
    t12 = $[19];
  }
  let t13;
  if ($[20] !== t9 || $[21] !== t12) {
    t13 = <HHStack direction="row" spacing={2} sx={t6}>{t9}{t12}</HHStack>;
    $[20] = t9;
    $[21] = t12;
    $[22] = t13;
  } else {
    t13 = $[22];
  }
  let t14;
  if ($[23] !== props.sxStyles.hhDialogContentText) {
    t14 = {
      ...props.sxStyles.hhDialogContentText,
      marginTop: "0px"
    };
    $[23] = props.sxStyles.hhDialogContentText;
    $[24] = t14;
  } else {
    t14 = $[24];
  }
  let t15;
  if ($[25] !== t14) {
    t15 = <HHDialogContentText sx={t14}>{USER_FEEDBACK.DIALOG_TEXTS.TITLE_BUG_REQUEST_QUES}</HHDialogContentText>;
    $[25] = t14;
    $[26] = t15;
  } else {
    t15 = $[26];
  }
  const t16 = typeChip === USER_FEEDBACK.BUG_STRING ? "secondary" : "default";
  let t17;
  if ($[27] === Symbol.for("react.memo_cache_sentinel")) {
    t17 = () => handleToggleBugFeatureChip(USER_FEEDBACK.BUG_STRING);
    $[27] = t17;
  } else {
    t17 = $[27];
  }
  let t18;
  if ($[28] !== t16) {
    t18 = <HHChip clickable={true} color={t16} hhVariant="filled" label={USER_FEEDBACK.BUG_STRING.charAt(0).toUpperCase() + USER_FEEDBACK.BUG_STRING.slice(1).toLocaleLowerCase()} onClick={t17} />;
    $[28] = t16;
    $[29] = t18;
  } else {
    t18 = $[29];
  }
  const t19 = typeChip === USER_FEEDBACK.REQUEST_STRING ? "secondary" : "default";
  let t20;
  if ($[30] === Symbol.for("react.memo_cache_sentinel")) {
    t20 = {
      marginLeft: "10px"
    };
    $[30] = t20;
  } else {
    t20 = $[30];
  }
  let t21;
  if ($[31] === Symbol.for("react.memo_cache_sentinel")) {
    t21 = () => handleToggleBugFeatureChip(USER_FEEDBACK.REQUEST_STRING);
    $[31] = t21;
  } else {
    t21 = $[31];
  }
  let t22;
  if ($[32] !== t19) {
    t22 = <HHChip clickable={true} hhVariant="filled" color={t19} layoutStyles={t20} label={USER_FEEDBACK.REQUEST_STRING.charAt(0).toUpperCase() + USER_FEEDBACK.REQUEST_STRING.slice(1).toLocaleLowerCase()} onClick={t21} />;
    $[32] = t19;
    $[33] = t22;
  } else {
    t22 = $[33];
  }
  const t23 = USER_FEEDBACK.DIALOG_TEXTS[(typeChip as FeedbackType)];
  let t24;
  if ($[34] !== props.sxStyles.hhDialogContentText || $[35] !== t23.TITLE) {
    t24 = <HHDialogContentText sx={props.sxStyles.hhDialogContentText}>{t23.TITLE}</HHDialogContentText>;
    $[34] = props.sxStyles.hhDialogContentText;
    $[35] = t23.TITLE;
    $[36] = t24;
  } else {
    t24 = $[36];
  }
  let t25;
  if ($[37] === Symbol.for("react.memo_cache_sentinel")) {
    t25 = {
      minRows: 1,
      className: "title-input"
    };
    $[37] = t25;
  } else {
    t25 = $[37];
  }
  const t26 = USER_FEEDBACK.DIALOG_TEXTS[(typeChip as FeedbackType)];
  let t27;
  if ($[38] === Symbol.for("react.memo_cache_sentinel")) {
    t27 = {
      width: "100%"
    };
    $[38] = t27;
  } else {
    t27 = $[38];
  }
  let t28;
  if ($[39] !== props) {
    t28 = e_1 => props.updateTicketData(prevData_1 => ({
      ...prevData_1,
      subject: e_1.target.value
    }));
    $[39] = props;
    $[40] = t28;
  } else {
    t28 = $[40];
  }
  const t29 = props.titleError.length ? true : false;
  let t30;
  if ($[41] !== t26.TITLE_PLACEHOLDER || $[42] !== props.freshdeskTicketDetails.subject || $[43] !== props.titleError || $[44] !== t28 || $[45] !== t29) {
    t30 = <HHTextField hhVariant="outlined" label="" InputProps={t25} placeholder={t26.TITLE_PLACEHOLDER} size="medium" defaultValue={props.freshdeskTicketDetails.subject} fullWidth={true} sx={t27} onChange={t28} error={t29} helperText={props.titleError} />;
    $[41] = t26.TITLE_PLACEHOLDER;
    $[42] = props.freshdeskTicketDetails.subject;
    $[43] = props.titleError;
    $[44] = t28;
    $[45] = t29;
    $[46] = t30;
  } else {
    t30 = $[46];
  }
  const t31 = USER_FEEDBACK.DIALOG_TEXTS[(typeChip as FeedbackType)];
  let t32;
  if ($[47] !== props.sxStyles.hhDialogContentText || $[48] !== t31.TITLE) {
    t32 = <HHDialogContentText sx={props.sxStyles.hhDialogContentText}>{t31.TITLE}</HHDialogContentText>;
    $[47] = props.sxStyles.hhDialogContentText;
    $[48] = t31.TITLE;
    $[49] = t32;
  } else {
    t32 = $[49];
  }
  const t33 = USER_FEEDBACK.DIALOG_TEXTS[(typeChip as FeedbackType)];
  let t34;
  if ($[50] === Symbol.for("react.memo_cache_sentinel")) {
    t34 = {
      width: "100%"
    };
    $[50] = t34;
  } else {
    t34 = $[50];
  }
  let t35;
  if ($[51] !== props) {
    t35 = e_2 => props.updateTicketData(prevData_2 => ({
      ...prevData_2,
      description: e_2.target.value
    }));
    $[51] = props;
    $[52] = t35;
  } else {
    t35 = $[52];
  }
  const t36 = props.descriptionError.length ? true : false;
  let t37;
  if ($[53] !== t33.DESCRIPTION_PLACEHOLDER || $[54] !== props.freshdeskTicketDetails.description || $[55] !== props.descriptionError || $[56] !== t35 || $[57] !== t36) {
    t37 = <HHTextField hhVariant="outlined" label="" multiline={true} fullWidth={true} rows={4} placeholder={t33.DESCRIPTION_PLACEHOLDER} size="medium" defaultValue={props.freshdeskTicketDetails.description} sx={t34} onChange={t35} error={t36} helperText={props.descriptionError} />;
    $[53] = t33.DESCRIPTION_PLACEHOLDER;
    $[54] = props.freshdeskTicketDetails.description;
    $[55] = props.descriptionError;
    $[56] = t35;
    $[57] = t36;
    $[58] = t37;
  } else {
    t37 = $[58];
  }
  let t38;
  if ($[59] !== props.sxStyles.hhDialogContentText) {
    t38 = <HHDialogContentText sx={props.sxStyles.hhDialogContentText}>{USER_FEEDBACK.DIALOG_TEXTS.MEMBER_ID_TITLE}</HHDialogContentText>;
    $[59] = props.sxStyles.hhDialogContentText;
    $[60] = t38;
  } else {
    t38 = $[60];
  }
  let t39;
  if ($[61] === Symbol.for("react.memo_cache_sentinel")) {
    t39 = {
      minRows: 1
    };
    $[61] = t39;
  } else {
    t39 = $[61];
  }
  let t40;
  if ($[62] === Symbol.for("react.memo_cache_sentinel")) {
    t40 = {
      width: "100%"
    };
    $[62] = t40;
  } else {
    t40 = $[62];
  }
  let t41;
  if ($[63] !== props) {
    t41 = e_3 => props.updateCustomFields(prevData_3 => ({
      ...prevData_3,
      cf_user_id: e_3.target.value
    }));
    $[63] = props;
    $[64] = t41;
  } else {
    t41 = $[64];
  }
  let t42;
  if ($[65] !== props.customFields.cf_user_id || $[66] !== t41) {
    t42 = <TextArea label="" placeholder={USER_FEEDBACK.DIALOG_TEXTS.MEMBER_ID_PLACEHOLDER} InputProps={t39} defaultValue={props.customFields.cf_user_id} sx={t40} onChange={t41} />;
    $[65] = props.customFields.cf_user_id;
    $[66] = t41;
    $[67] = t42;
  } else {
    t42 = $[67];
  }
  let t43;
  if ($[68] !== props.sxStyles.hhDialogContentText) {
    t43 = {
      ...props.sxStyles.hhDialogContentText,
      marginBottom: "0px"
    };
    $[68] = props.sxStyles.hhDialogContentText;
    $[69] = t43;
  } else {
    t43 = $[69];
  }
  let t44;
  if ($[70] !== t43) {
    t44 = <HHDialogContentText sx={t43}>{USER_FEEDBACK.DIALOG_TEXTS.ATTACHMENT_TEXT}</HHDialogContentText>;
    $[70] = t43;
    $[71] = t44;
  } else {
    t44 = $[71];
  }
  let t45;
  let t46;
  if ($[72] === Symbol.for("react.memo_cache_sentinel")) {
    t45 = {
      display: "none"
    };
    t46 = e_4 => e_4.stopPropagation();
    $[72] = t45;
    $[73] = t46;
  } else {
    t45 = $[72];
    t46 = $[73];
  }
  let t47;
  if ($[74] !== fileInputRef || $[75] !== props.handleFileUpload) {
    t47 = <input data-testid={USER_FEEDBACK.TEST_IDS.FILE_INPUT} type="file" accept="image/*, video/*, .har, .doc, .docx, .pdf" multiple={true} ref={fileInputRef} style={t45} onClick={t46} onChange={props.handleFileUpload} />;
    $[74] = fileInputRef;
    $[75] = props.handleFileUpload;
    $[76] = t47;
  } else {
    t47 = $[76];
  }
  let t48;
  if ($[77] === Symbol.for("react.memo_cache_sentinel")) {
    t48 = {
      marginTop: "8px",
      lineHeight: "40px"
    };
    $[77] = t48;
  } else {
    t48 = $[77];
  }
  let t49;
  if ($[78] === Symbol.for("react.memo_cache_sentinel")) {
    t49 = <UploadFileSharp sx={{
      marginRight: "8px"
    }} />;
    $[78] = t49;
  } else {
    t49 = $[78];
  }
  let t50;
  if ($[79] !== handleButtonClick) {
    t50 = <HHButton hhVariant="outlined" layoutStyles={t48} onClick={handleButtonClick}>{t49}{" "}{USER_FEEDBACK.UPLOAD_FILES_TEXT}</HHButton>;
    $[79] = handleButtonClick;
    $[80] = t50;
  } else {
    t50 = $[80];
  }
  let t51;
  if ($[81] === Symbol.for("react.memo_cache_sentinel")) {
    t51 = <br />;
    $[81] = t51;
  } else {
    t51 = $[81];
  }
  let t52;
  if ($[82] === Symbol.for("react.memo_cache_sentinel")) {
    t52 = <InfoOutlined sx={{
      color: "rgba(103, 111, 109, 1)"
    }} />;
    $[82] = t52;
  } else {
    t52 = $[82];
  }
  let t53;
  if ($[83] !== props.sxStyles.phiDisclaimerTypography) {
    t53 = <Stack direction="row" spacing={2}>{t52}{" "}<Typography sx={props.sxStyles.phiDisclaimerTypography} align="center">{USER_FEEDBACK.DISCLAIMER_TEXT}</Typography></Stack>;
    $[83] = props.sxStyles.phiDisclaimerTypography;
    $[84] = t53;
  } else {
    t53 = $[84];
  }
  let t54;
  if ($[85] !== props.sxStyles.phiDisclaimerBox || $[86] !== t53) {
    t54 = <Box sx={props.sxStyles.phiDisclaimerBox}>{t53}</Box>;
    $[85] = props.sxStyles.phiDisclaimerBox;
    $[86] = t53;
    $[87] = t54;
  } else {
    t54 = $[87];
  }
  let t55;
  if ($[88] !== props) {
    t55 = props.feedbackFiles && props.feedbackFiles.length > 0 && <HHList>{Array.from((props.feedbackFiles as File[])).map((file, index) => <HHListItem key={file.name} sx={props.sxStyles.hhListItem}><Stack direction="row" spacing="12px" alignItems="center" sx={props.sxStyles.stack}><DoneRounded sx={{
            color: "rgba(0, 0, 0, 0.54)"
          }} /><Typography variant="body1" sx={{
            flex: 1
          }}>{file.name}</Typography><DeleteOutlineRounded data-testid={USER_FEEDBACK.TEST_IDS.DELETE_BUTTON + index} sx={{
            marginLeft: "100px",
            color: "rgba(0, 0, 0, 0.54)",
            cursor: "pointer"
          }} onClick={() => props.handleDeleteItem(index)} /></Stack></HHListItem>)}</HHList>;
    $[88] = props;
    $[89] = t55;
  } else {
    t55 = $[89];
  }
  const t56 = props.loader ? undefined : props.handleSubmit;
  let t57;
  if ($[90] !== t56 || $[91] !== props.loader) {
    t57 = <HHButton hhVariant="contained" color="primary" onClick={t56} fullWidth={true} disabled={props.loader}>{USER_FEEDBACK.SUBMIT_FORM_BUTTON_TEXT}</HHButton>;
    $[90] = t56;
    $[91] = props.loader;
    $[92] = t57;
  } else {
    t57 = $[92];
  }
  let t58;
  if ($[93] !== props.sxStyles.hhDialogActions || $[94] !== t57) {
    t58 = <HHDialogActions sx={props.sxStyles.hhDialogActions}>{t57}</HHDialogActions>;
    $[93] = props.sxStyles.hhDialogActions;
    $[94] = t57;
    $[95] = t58;
  } else {
    t58 = $[95];
  }
  let t59;
  if ($[96] !== t13 || $[97] !== t15 || $[98] !== t18 || $[99] !== t22 || $[100] !== t24 || $[101] !== t30 || $[102] !== t32 || $[103] !== t37 || $[104] !== t38 || $[105] !== t42 || $[106] !== t44 || $[107] !== t47 || $[108] !== t50 || $[109] !== t54 || $[110] !== t55 || $[111] !== t58) {
    t59 = <HHDialogContent sx={t3}>{t5}{t13}{t15}{t18}{t22}{t24}{t30}{t32}{t37}{t38}{t42}{t44}{t47}{t50}{t51}{t54}{t55}{t58}</HHDialogContent>;
    $[96] = t13;
    $[97] = t15;
    $[98] = t18;
    $[99] = t22;
    $[100] = t24;
    $[101] = t30;
    $[102] = t32;
    $[103] = t37;
    $[104] = t38;
    $[105] = t42;
    $[106] = t44;
    $[107] = t47;
    $[108] = t50;
    $[109] = t54;
    $[110] = t55;
    $[111] = t58;
    $[112] = t59;
  } else {
    t59 = $[112];
  }
  return t59;
};